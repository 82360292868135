@import "~bootstrap/dist/css/bootstrap.min.css";


/* NEW SECTION */
/* to disable old css replace all oldRef classes with nooldRed and vice-versa */
h1.golded.oldRef {
    font-size:calc(1.325rem + .9vw) !important;
}

h2.oldRef{
    font-size: calc(1.275rem + .3vw);
}

h1#h1itw.oldRef{
    font-size: 2rem;

}

.h2articleview.oldRef{
    font-size: calc(1.275rem + .3vw);
}

.articleviewcontent h2.oldRef{
    font-size: 1rem;
}

#h1actu.oldRef{
font-size: 2rem;
}

#h1apropos.oldRef{
    font-size: 5vw;
}
@media screen and (min-width: 992px)
{
    .logoAccueil.oldRef img{
        height: 50%;
    }
}

@media screen and (min-width: 768px){
    .text-explicatif-formation h3.oldRef {
        text-decoration: underline;
        font-size:calc(1.3rem + .6vw);
    }
}

/* END SECTION */



html, body {
    margin: 0;
    scroll-behavior: smooth;
}
#wrapperBase
{
    scroll-behavior: smooth;

}
body {
    background-color: #EDDACB;
    overflow-x: hidden;
}
td{
    padding: 0 !important;
}
video{
    max-width: 100%;
    height: auto !important;
}
.justify-content-even{
    justify-content: space-evenly;
}
    /*
.img-background-home
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
*/


#itw_content iframe{
    width: 80%;
    height: auto;
    aspect-ratio: 16 / 9;
}

#itw_content img{
    max-width: 100% !important; 
    padding: 0.5rem !important; 
    height: auto !important; 
}
#itw_content p{
   margin: 0;
}


table iframe{
    width: 80%;
    height: auto;
    aspect-ratio: 16 / 9;
}
table a {
    overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-word;

  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
table img{
    max-width: 100% !important; 
    padding: 0.5rem !important; 
    height: auto !important; 
}
table p{
   margin: 0;
}

#paragraphes > *{
    max-width: 100%;
}
.paracontent > *{
    max-width: 100%;
}

.try{
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    float:none !important;
    padding: 0 !important;
}

.paracontent img{
    max-width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}


/* Couleur d'arrière plan creme du body */
.bg-creme{
    background-color: #EDDACB;
}


/* Couleur d'arrière plan vert de la navbar*/
.bg-greenCustom
{
    background-color:#104040;
}


.smalled{
    font-size:0.5em;
}
/* Font Family Georgia */
.ff-georgia
{
    font-family: Georgia, 'Times New Roman', Times, serif;
}

/* Couleur doré utilisé partout dans le site */
.golded
{
    color: #D47400;
}

/* Onglet actif */
.active
{
    border-bottom: 3px solid #D47400;
}

.banniere
{
    width: 100%;
    position: relative;
}

.logo
{
    display: none;
}

.alone{
    display: flex !important; 
    width:auto !important;
    justify-content: center !important;
}

.logo-label
{
    width: 100%;
}

a.active
{
    margin-left: 0; 
    margin-right: 0; 
}


#particles-js-nav
{
    display: none;
}

.spanmenu{
    font-size:0.7em;
}

hr 
{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.hrConsulting
{
    border: none !important;
    height: 2px !important; 
    background-color:#D47400;
    opacity: 1;
}

/* NE PAS DELETE, ELLE N'EXISTE PLUS DANS LE CODE, MAIS EN BDD OUI */
.boxTopApropos
{
    border: 3px solid #D47400;
    padding: 1em;
}


.z-index1
{
    z-index: 1;
}

.underLogo {
	display: inline-block;
	position: relative;
	padding-bottom: 3px;
    font-size: 1em;
    color:#fea73d

}
.underLogo:after {
	content: '';
	display: block;
	margin: auto;
	height: 3px;
	width: 0px;
	background: transparent;
	transition: width .5s ease, background-color .5s ease;
}
#logoContainer:hover a .underLogo:after {
	width: 100%;
	background: #d47400;
}

.error404Perso
{
    max-width: 100%;
}

.hide-on-mobile 
{
    display: none !important;
}


.thirdHeightViewport
{
    overflow-x: hidden;
}

span.navbar-toggler-icon
{
    transition: linear 1s;
}

.interview
{
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    margin-bottom: 2em;
}

.interview img
{
    height: 150px;
    width: 150px;
}


.brPagination2
{
    flex-basis: 100%;
    height: 0;
}

@media screen and (max-width:480px)
{
    
    .brPagination
    {
        flex-basis: 100%;
        height: 0;
    }
}
@media screen and (max-width:350px) 
{
    .underLogo{
        font-size: 0.83em !important;
    }
}


@media screen and (min-width:351px) and (max-width:400px) 
{
    .underLogo{
        font-size: 0.85em !important;
    }

}




@media screen and (max-width:400px) 
{

    .interview
    {
        width: 100% !important;
        text-align: center;
    }    
}
#containerInterview
{
    opacity: 1;
    transition: opacity 0.5 linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear;
}

.opacity0
{
    opacity: 0 !important;
    transition: opacity 0.5 linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear;
}


.con {

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;

}

.wrapper {
    background-color: #EDDACB;
    flex: 1;
    padding: 10px;
}

.editcontent{
    transform: scale(0);
    transition: 0.5s;
}
.animateedit{
    transform: scale(1);
}

.editmode{
  background-color: #104040;
  transition: 0.5s;
}

.paracontent{
    transform: scale(1);
    transition: 0.5s;
}
.paracontent.paracontentanim{
    transform: scale(0);
    transition: 0.5s;
}

.footer{
    background-color: #104040;
    bottom: 0;
    position: relative;
}

.footercontent{
    position: relative;
    z-index: 1000;

}

a.golded:visited{
    color:#d47400;
}


#particles-js-footer .particles-js-canvas-el{
    z-index: 500;

    -webkit-mask-image: linear-gradient(to left, transparent 30%, rgba(16,65,65,1));
    mask-image: linear-gradient(to left, transparent 30%, rgba(16,65,65,1));

    width: 100% !important;
    height: 100% !important;
    left:0;
    top:0;
    position: absolute;
}

.card-img-top
{
    max-width: 300px;
    height: 200px;
}

/* Par défaut en collapse, génère un scroll horizontal */
table
{
    border-collapse: unset !important;
}

p{
    margin:0;
}


.btnFbCustom
{
    background-color: #4267B2;
    color: white;
}

.IN-widget span
{
    line-height: inherit;
}

.marginCustom
{
    transform:scale(0.7);
}



.btn-twitter{
    background-color: #1DA1F2;
color:white;
}

.btnLinkedIn{
    background-color: #0e76a8;
    color: white;
}
a{overflow-wrap: break-word;word-wrap: break-word;-ms-word-break: break-word;word-break: break-word; -ms-hyphens: auto;-moz-hyphens: auto;-webkit-hyphens: auto; hyphens: auto;}

.formUsername button
{
    margin-top: 5em !important;
}

.borderLeftCustom
{
    border-left: 1px solid black;
}
    
.page-item.active .page-link
{
    background-color: #104040 !important;
    color: white !important;
}

.page-link
{
    background-color: white !important;
    color: #104040 !important;
}

@media screen and (max-width:576px) 
{
  
    #articleviewcontent img{
        max-width: 100%;
        height: auto;
        float:none !important;
        display:block !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    #articleviewcontent table tbody tr{
        display: flex !important;
        flex-direction: column !important;
    }
    #articleviewcontent table tbody tr td{
        width: auto !important;
        height: auto !important;
    }
    #articleviewcontent table tbody tr{
        width: auto !important;
        height: auto !important;
    }
    #articleviewcontent table{
        margin-left: auto !important;
        margin-right: auto !important;
        height: auto !important;
        width: auto !important;

    }
    #articleviewcontent table img{
        float:none !important;
    }


    /* ##### */

    #allActu img{
        float:none !important;
        display:block !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }


    #allActu table tbody tr{
        display: flex !important;
        flex-direction: column !important;
    }
    #allActu table tbody tr td{
        width: auto !important;
        height: auto !important;
    }
    #allActu table tbody tr{
        width: auto !important;
        height: auto !important;
    }
    #allActu table{
        margin-left: auto !important;
        margin-right: auto !important;
        height: auto !important;
        width: auto !important;

    }
    #allActu table img{
        float:none !important;
    }





    /* ###########" */
    #itw_content table tbody tr{
        display: flex !important;
        flex-direction: column !important;
    }
    #itw_content table tbody tr td{
        width: auto !important;
        height: auto !important;
        text-align: center;
    }
    #itw_content table tbody tr{
        width: auto !important;
        height: auto !important;
    }
    #itw_content table{
        margin-left: auto !important;
        margin-right: auto !important;
        height: auto !important;
        width: auto !important;
        padding-left: 2em !important;
        padding-right: 2em !important;
    }
    #itw_content table img{
        float:none !important;
    }
    #itw_content img{
        float:none !important;
        display:block !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .aproposcontent img{
        float:none !important;
        display:block !important;
        margin-left: auto !important;
        margin-right: auto !important;

    }


    .aproposcontent table tbody tr{
        display: flex !important;
        flex-direction: column !important;
    }
    .aproposcontent table tbody tr td{
        width: auto !important;
        height: auto !important;
        text-align: center;
    }
    .aproposcontent table tbody tr{
        width: auto !important;
        height: auto !important;
    }
    .aproposcontent table{
        margin-left: auto !important;
        margin-right: auto !important;
        height: auto !important;
        width: auto !important;

    }
    .aproposcontent table img{
        float:none !important;
    }
}

.aproposcontent img
{
    max-width: 100%;
    height: auto;
}

@media screen and (min-width: 568px)
{
    .spanmenu{
        font-size: 1em !important;
    }
    span.navbar-toggler-icon
    {
       transition: linear 1s;
        width: 75%;
        height: 75%;
    }
}


@media screen and (min-width:768px) and (max-width:991px){
   .nav-link{    
       font-size: 0.70em !important;
   }
}
@media screen and (max-width:991px)
{
    #paragraphes table tr{
        display: table;
        width: 100%;
    }
    
    #paragraphes table td{
        display: table-row;
        
    }
}

@media screen and (min-width: 1024px) { 
    .hide-on-mobile {
      display: grid !important;
    }  


}

    /* DÉBUT MEDIA QUERIE 768px */
    /* DÉBUT MEDIA QUERIE 768px */
    /* DÉBUT MEDIA QUERIE 768px */


@media screen and (min-width: 768px) /* Breakpoint md de Bootstrap */
{
    .paracontent img{
        max-width: 100%;
        height: auto;
        padding-left: 1em;
        padding-right: 1em;
    }
    

    .paracontent p{
        font-size: 1rem;
     }

    .nav-link
    {
        font-size: 0.95em;
    }

    #particles-js-nav
    {
        z-index: 1;
    }
    .logo, #particles-js-nav
    {
        display: block;
    }

    .logo, .logo-label
    {
        height: 3rem;
    }
    button.navbar-toggler 
    {
        display: none;
    }

    #particles-js-nav .particles-js-canvas-el
    {
        z-index: 500;
        -webkit-mask-image: linear-gradient(to right, transparent 30%, rgba(16,65,65,1));
        mask-image: linear-gradient(to right, transparent 30%, rgba(16,65,65,1));
        width: 100% !important;
        height: 100% !important;
        right:0;
        top:0;
        position: absolute;
    }



 

    .boxTopApropos
    {
        margin-left: 7em;
        margin-right: 7em;
    /*    border: 2px solid #D47400;*/
        padding: 1em;
    }

    /* START ANIMATION HOVER NAVBAR */
    /* START ANIMATION HOVER NAVBAR */
    /* START ANIMATION HOVER NAVBAR */

    .shift .nav-item a 
    {
        position:relative;
        z-index: 1;
    }

    .shift .nav-item a,
    .shift .nav-item a:after,
    .shift .nav-item a:before {
    transition: all .6s;
    }

    .shift .nav-item a:hover 
    {
        color: black;
    }

    .shift .nav-item a:after 
    {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 1px;
        content: '.';
        color: transparent;
        background: #D47400;
        visibility: none;
        opacity: 0;
        z-index: -1;
    }
    .shift .nav-item a:hover:after 
    {
        opacity: 1;
        visibility: visible;
        height: 100%;
    }

    /* END ANIMATION HOVER NAVBAR */
    /* END ANIMATION HOVER NAVBAR */
    /* END ANIMATION HOVER NAVBAR */


    /* START ANIMATION FORMATION */
    /* START ANIMATION FORMATION */
    /* START ANIMATION FORMATION */


    .fade-in
    {
        opacity: 0;
        transition: opacity 250ms ease-in;
    }

    .fade-in2
    {
        opacity: 0;
        transition: opacity 1000ms ease-in;
    }

    .fade-in.appear, .fade-in2.appear  
    {
        opacity: 1;
    }

    .from-left
    {
        transform: translateX(-50%);
    }

    .from-right
    {
        transform: translateX(50%);
    }

    .from-left,.from-right
    {
        opacity: 0;
        transition: opacity 400ms ease-in ,transform 400ms ease-in;
    }

    .from-left2
    {
        transform: translateX(-50%);
        transition: opacity 1000ms ease-in ,transform 1000ms ease-in;
    }

    .from-left.appear,.from-right.appear,.from-left2.appear
    {
        transform:translateX(0);
    }
    
    /* END ANIMATION FORMATION */
    /* END ANIMATION FORMATION */
    /* END ANIMATION FORMATION */

    #searchBar
    {
        width: auto;
        margin-right: 2em;
    }

    .error404Perso
    {
        max-width: 33%;
    }

}  
    /* FIN MEDIA QUERIE 768px */
    /* FIN MEDIA QUERIE 768px */
    /* FIN MEDIA QUERIE 768px */

    /* DÉBUT MEDIA QUERIE 992px */
    /* DÉBUT MEDIA QUERIE 992px */
    /* DÉBUT MEDIA QUERIE 992px */
    
    .paddingDeOuf
    {
        padding-left: 0;
        padding-right: 0;
    }
@media screen and (min-width: 992px) /* Breakpoint xl de Bootstrap */
{

    .paddingDeOuf
    {
        padding-left: 10em;
        padding-right: 10em;
    }

    .text-explicatif-formation
    {
        padding-left: 10em;
        padding-right: 10em;
    }

}

    /* FIN MEDIA QUERIE 992px */
    /* FIN MEDIA QUERIE 992px */
    /* FIN MEDIA QUERIE 992px */

/* Go to top button actu et itw */
#myBtn 
{
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 9999;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #104040;
    color: #D47400;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
}

#myBtn:hover 
{
    background-color: #555;
}